
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { getUsersbyOrgId, updateUser, deleteUser } from "../../api/Users";
import { ProgressSpinner } from 'primereact/progressspinner';
import WarningLogo from '../../images/warning.png';
// import { inviteUser } from "../../api/Participants";
import { getUser } from "../../api/Users";
import Page403 from '../Common/Page403';
import Topbar from '../../components/Topbar';
import { authObjType, channelType, dropDown } from '../../types/types';
import { AuthContext } from '../../types/authContext';
import { ChannelContext } from '../../types/channelContext';

const genders = [
    { name: "Male" }, { name: "Female" }
]

const statuses = [
    { name: "Active" }, { name: "Not Active" }
]

const userTypes = [
    { name: "admin" }, { name: "user" }
]



function UsersList() {
    const toast = useRef(null);
    const user = useContext(AuthContext);
    const channelList = useContext(ChannelContext);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [authObj, setAuthObj] = useState<authObjType>();
    const [users, setUsers] = useState([]);
    const [editUser, setEditUser] = useState({});
    const [userData, setUserData] = useState(null);
    const [name, setName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [status, setStatus] = useState<dropDown>();
    const [userType, setUserType] = useState<dropDown>();
    const [gender, setGender] = useState<dropDown>();
    const [email, setEmail] = useState('');
    const [dob, setDOB] = useState('');
    const [uID, setUID] = useState('');
    const [id, setId] = useState('');
    const [uchannel, setUchannel] = useState<channelType>();
    const [updated, setUpdated] = useState(false);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState(false);
    // const savedValue = JSON.parse(window.localStorage.getItem("auth"));
    const savedValue = user.auth;

    useEffect(() => {
        setLoading(true);
        setAuthObj(savedValue);
        getUsersbyOrg(savedValue.team.id);
        getTempalte(savedValue.team.id + savedValue.channel.slackUserId);

    }, [updated]);

    const getUsersbyOrg = async (params) => {
        let users = await getUsersbyOrgId(params);

        let userArr = [];
        users?.docs?.map((item) => {
            let a = {
                name: item.data().profile.name ? item.data().profile.name : "-",
                gender: item.data().profile.gender ? item.data().profile.gender : "-",
                occupation: item.data().profile.occupation ? item.data().profile.occupation : "-",
                email: item.data().profile.email ? item.data().profile.occupation : "-",
                dob: item.data().profile.dob ? item.data().profile.dob : "-",
                activeStatus: item.data().activeStatus ? "Active" : "Not Active",
                userType: item.data().userType,
                channel: item.data().channel,
                uID: item.data().teamId + item.data().channel.slackUserId,
                team: item.data().teamId,
                id: item.data().channel.slackUserId
            }
            userArr.push(a);
        });
        setUsers(userArr);
        let chanArr = [];
        // const chan = JSON.parse(window.localStorage.getItem("channelList"));
        const chan = channelList.channels;
        const result2 = Object.keys(chan).map((key) => chan[key]);
        result2.map((res) => {
            chanArr.push({ name: res.name, id: res.id })
        })
        chanArr.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          
        setChannels(chanArr);
    }


    const getTempalte = async (params) => {
        const user = await getUser(params);
        setUserData(user);
        setLoading(false);
    };

    const onEditClicked = async (value) => {
        setEditVisible(true);
        setEditUser(value);
        setUchannel(value.channel);
        setName(value.name);
        setOccupation(value.occupation);
        setEmail(value.email);
        setDOB(value.dob);
        genders.map((gen) => {
            if (gen.name == value.gender)
                setGender(gen);
        })
        statuses.map((gen) => {
            if (gen.name == value.activeStatus)
                setStatus(gen);
        })
        userTypes.map((gen) => {
            if (gen.name == value.userType)
                setUserType(gen);
        })
        setId(value.id);
        setUID(value.uID);
    }

    const onUpdate = async () => {
        const data = {
            uData: {
                name: name,
                occupation: occupation,
                email: email,
                dob: dob,
                gender: gender && gender.name ? gender.name : ''
            },
            activeStatus: status.name,
            userType: userType.name
        }
        let flowId = await updateUser(data, uID, authObj, id).then((res) => {
            toast.current.show({ severity: 'info', summary: 'Success', detail: 'Changes Saved' });
            setEditVisible(false);
            setUpdated(true);
        });
        // if (flowId) {
        //     toast.current.show({ severity: 'info', summary: 'Success', detail: 'Changes Saved' });
        // }else {
        //     toast.current.show({ severity: 'danger', summary: 'Failed', detail: 'Something went wrong' });
        // }

    };

    const onDelete = async () => {
        await deleteUser(uID, authObj, id);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'User Deleted' });
        setDeleteVisible(false);
        setEditVisible(false);
    }

    const newUser = async (token, email, channel_ids, team_id) => {
        // const responseFromAPI3 = async () => {
        //     const response = await inviteUser(token, email, channel_ids, team_id)

        //     return response;
        // };

        // responseFromAPI3().then((data) => {
        //     if (data?.message === "success") {

        //     } else {
        //     }
        // });
    };

    const onNewEmailSubmit = async () => {

        // if (newEmail == null || selectedChannel == []) {
        if (newEmail == null) {
            setError(true);
        } else {
            let chanArr = selectedChannel.map((chan) => {
                return chan.id;
            })
            // newUser(JSON.stringify({ token: authObj.token }), JSON.stringify({email: newEmail}), JSON.stringify({channel_ids: chanArr}), JSON.stringify({team_id: authObj.team.id}));
            newUser(JSON.stringify({ token: authObj.token }), newEmail, chanArr.toString(), authObj.team.id);

        }
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>

            <Button className="mx-5" icon="pi pi-plus" severity="success" rounded onClick={() => setVisible(true)} />

        </div>
    );

    const headerEdit = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Edit User</span>
            {uchannel && uchannel.slackUserId == authObj.channel.slackUserId ? (
                <div />) : (
                <Button className="mx-5" icon="pi pi-trash" severity="danger" rounded onClick={() => setDeleteVisible(true)} />
            )}

        </div>
    );
    return (
        <>
        {userData && userData.userType == "user" ? (<Page403 />)  : (
                <>
                    <div>
                        <div> <Topbar title={"Members"} /></div>
                    </div>
                    <div className="card mx-5 my-5 ">
                        <div className="card">
                            {loading ? (
                                <div className="flex align-items-center justify-content-center flex-wrap" >
                                    <h1><ProgressSpinner /> </h1>
                                </div>
                            ) : (
                                <>
                                    <Toast ref={toast}></Toast>
                                    <DataTable header={header} value={users} tableStyle={{ minWidth: '50rem' }}>
                                        <Column field="name" header="Name"></Column>
                                        <Column field="gender" header="Gender"></Column>
                                        <Column field="activeStatus" header="Status"></Column>
                                        <Column field="userType" header="User Type"></Column>
                                        <Column field="occupation" header="Occupation"></Column>
                                        {userData && userData.userType == 'admin' ? (
                                            <Column header="Edit" body={(data, props) => (<Button severity="secondary" icon="pi pi-file-edit" outlined rounded onClick={() => onEditClicked(data)} />)}></Column>
                                        ) : (<div />)}
                                    </DataTable>
                                </>

                            )}


                            <Dialog header={(<p style={{ marginTop: 0 }}>Add member</p>)} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>

                                <div className="flex flex-wrap">
                                    <p>Email</p>
                                    <InputText value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder="Email" keyfilter="email" className="w-full" />
                                    {error && newEmail == null ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}
                                    <p>Channel (Select 1 or More)</p>
                                    <MultiSelect value={selectedChannel} invalid={error && selectedChannel == null} multiple={true} onChange={(e) => setSelectedChannel(e.value)} options={channels} optionLabel="name"
                                        className="w-full" />
                                    <label>{" "}</label>
                                    {error && selectedChannel == null ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}

                                    <Button label="Confirm" style={{ marginTop: 10, width: '100%' }} onClick={() => onNewEmailSubmit()} severity="help" />



                                    {/* <p>Channel</p>
                        <Dropdown value={selectedChannel} invalid={error && selectedChannel == null} onChange={(e) => setSelectedChannel(e.value)} options={channels} optionLabel="name"
                            className="w-full" />
                        {error && selectedChannel == null ? (
                            <small id="error">
                                Field can not be empty
                            </small>
                        ) : (<small />)} */}
                                </div>
                            </Dialog>

                            <Dialog header={headerEdit} visible={editVisible} style={{ width: '65vw' }} onHide={() => setEditVisible(false)}>

                                <div className="flex flex-wrap">

                                    <div className="col">
                                        <div className="my-4">
                                            <label >Name</label>
                                            <InputText defaultValue={name} onChange={(e) => setName(e.target.value)} className="w-full" />
                                        </div>

                                        <div className="my-4">
                                            <label >Status</label>
                                            <Dropdown value={status}
                                                options={statuses} onChange={(e) => setStatus(e.value)} optionLabel="name" className="w-full" />
                                        </div>

                                        <div className="my-4">
                                            <label >Occupation</label>
                                            <InputText defaultValue={occupation} onChange={(e) => setOccupation(e.target.value)} className="w-full" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="my-4">
                                            <label >Gender</label>
                                            <Dropdown value={gender} onChange={(e) => setGender(e.value)} options={genders} optionLabel="name" className="w-full" />
                                        </div>

                                        <div className="my-4">
                                            <label >User Type</label>
                                            <Dropdown value={userType} onChange={(e) => setUserType(e.value)} options={userTypes} optionLabel="name" className="w-full" />
                                        </div>
                                    </div>
                                </div>


                                <div className=" flex align-content-end justify-content-end flex-nowrap m-2 gap-3" style={{ width: '100%', }}>

                                    <div className="p-0">
                                        <Button label="Go back" outlined rounded severity="secondary" aria-label="Cancel" size="small" onClick={() => setEditVisible(false)} />
                                    </div>
                                    <div className="p-0">
                                        <Button label="Save" rounded severity="info" aria-label="Cancel" size="small" onClick={() => onUpdate()} />
                                    </div>
                                </div>


                            </Dialog>

                            <Dialog visible={deleteVisible} style={{ width: '40vw' }} onHide={() => setDeleteVisible(false)}>
                                <div className="flex text-center justify-content-center flex-wrap">
                                    <img alt="Card" src={WarningLogo} /></div>

                                <div className="text-center justify-content-center font-bold border-round">
                                    <h2 style={{ color: '#000766' }}>You are about to delete this user</h2>
                                    <p>Are you sure you want to delete this? This action can not be undone!</p>
                                </div>
                                <div className="flex justify-content-evenly flex-wrap m-2">

                                    <Button label="No, go back" severity="danger" text aria-label="Edit" onClick={() => setDeleteVisible(false)} />
                                    <Button label="Delete" color="#005CE8" aria-label="Edit" onClick={() => onDelete()} />
                                </div>
                            </Dialog>

                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default UsersList;