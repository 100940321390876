import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './theme.css';
import App from './App';
import '/node_modules/primeflex/primeflex.css'
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import { AuthProvider } from './types/authContext';
import { ChannelProvider } from './types/channelContext';
import { FlowProvider } from './types/flowContext';
import { ParticipantProvider } from './types/participantContext';
import { TemplateProvider } from './types/templateContext';
// import "/node_modules/primereact/resources/themes/lara-light-cyan/theme.css";
// export const UserContext = React.createContext(null);
// ReactDOM.render(

//   <React.StrictMode>
//     <link href="https://fonts.googleapis.com/css?family=Bungee+Inline" rel="stylesheet"></link>
//     <PrimeReactProvider>
//     <App />
//     </PrimeReactProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


// export default function Index() {

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const [name, setName] = useState('Name');



//   return (
root.render(
  // <React.StrictMode>
  <PrimeReactProvider>
    <AuthProvider>
      <ChannelProvider>
        <FlowProvider>
          <ParticipantProvider>
            <TemplateProvider>
              {/* <UserContext.Provider value={{ name, setName }}> */}
              <link href="https://fonts.googleapis.com/css?family=Bungee+Inline" rel="stylesheet"></link>
              <App />
            </TemplateProvider>
          </ParticipantProvider>
        </FlowProvider>
      </ChannelProvider>
    </AuthProvider>
  </PrimeReactProvider>
  // </React.StrictMode>,

)
//   )
// }
// store.subscribe(() => root.render(<App />));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
