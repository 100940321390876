import React, { useRef, useState, useEffect, useContext } from 'react';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import ImageUploader, { FileObjectType } from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { getUser, updateUserData } from "../../api/Users";
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from "moment";
import { user } from '../../types/types';
import { AuthContext } from '../../types/authContext';

const genders = [
    { name: "Male" }, { name: "Female" }
]

export const MyProfile: React.FC = () => {
    const toast = useRef(null);
    const user = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    // const [userData, setUserData] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [occupation, setOccupation] = useState('');
    const [gender, setGender] = useState(genders[0]);
    const [dob, setDOB] = useState('');
    const [dateError, setDateError] = useState(false);
    const [uID, setuID] = useState(null);
    const [userId, setUserId] = useState(null);
    const [teamId, setTeamId] = useState(null);


    useEffect(() => {
        setLoading(true);
        const savedValue = user.auth;
        // const savedValue = JSON.parse(window.localStorage.getItem("auth") || "") ;
        getTempalte(savedValue.team.id + savedValue.channel.slackUserId);
        setuID(savedValue.team.id + savedValue.channel.slackUserId);
        setUserId(savedValue.channel.slackUserId);
        setTeamId(savedValue.team.id);
        
    }, []);

    const getTempalte = async (params: string) => {
        const user = await getUser(params) as user;
        // setUserData(user?.profile);
        setName(user?.profile.name);
        setEmail(user?.profile.email);
        setOccupation(user?.profile.occupation);
        setDOB(user?.profile.dob);
        genders.map((gen) => {
            if (gen.name == user?.profile.gender)
                setGender(gen);
        })
        setLoading(false);
    };

    const changeDOB = async (value: any) => {
        if(moment() < moment(value)){
            setDateError(true);
        }else{
            setDateError(false)
            setDOB(value);
        }

    }

    const onUpdate = async () => {
        let uData = {
            name: name,
            occupation: occupation,
            email: email,
            dob: dob,
            gender: gender.name
        }
        let flowId = await updateUserData(uData, uID, userId, teamId).then((res)=>{
            if (toast.current) {
                toast.current.show({ severity: 'info', summary: 'Success', detail: 'Changes Saved' });
              }
           
        });
        // if (flowId) {
        //     toast.current.show({ severity: 'info', summary: 'Success', detail: 'Changes Saved' });
        // }else {
        //     toast.current.show({ severity: 'danger', summary: 'Failed', detail: 'Something went wrong' });
        // }
        
    };

    const getImageFileObject = (imageFile: FileObjectType) => {

    }

    const runAfterImageDelete = (file: FileObjectType) => {
    }
    return (
        <Card>
            {loading ? (
                <div className="flex align-items-center justify-content-center flex-wrap" >
                    <h1><ProgressSpinner /> </h1>
                </div>
            ) : (
                <>
                    <div>
                        <p>Profile Picture</p>
                        <Toast ref={toast}></Toast>
                        <ImageUploader
                            onFileAdded={(img) => getImageFileObject(img)}
                            onFileRemoved={(img) => runAfterImageDelete(img)}
                            uploadIcon={<i style={{ fontSize: 30 }} className="pi pi-image"></i>}
                        />
                        <hr style={{ borderTop: "1px solid", borderColor: "#E0E4EC", marginTop: 20 }} />


                    </div>

                    <div>
                        <div className="flex flex-wrap gap-3 mb-4">
                            <div className="flex-auto">
                                <div>
                                    <div className='my-3'>
                                        <label htmlFor="name" className="font-bold block mb-2">
                                            Name
                                        </label>
                                        <InputText value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} className="w-full" />
                                    </div>

                                    <div className='my-3'>
                                        <label htmlFor="occupation" className="font-bold block mb-2">
                                            Occupation
                                        </label>
                                        <InputText value={occupation} placeholder="Occupation" onChange={(e) => setOccupation(e.target.value)} className="w-full" />
                                    </div>
                                    <div className='my-3'>
                                        <label htmlFor="dob" className="font-bold block mb-2">
                                            Date of Birth
                                        </label>
                                        <InputMask  mask="9999-99-99" value={dob} placeholder="YYYY-MM-DD"  className="w-full" onChange={(e) => changeDOB(e.target.value)} />
                                        {dateError ? (
                                                <small id="error">
                                                    Invalid Date of Birth
                                                </small>
                                            )  : (<small />)}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-auto">
                                <div>
                                    <div className='my-3'>
                                        <label htmlFor="email" className="font-bold block mb-2">
                                            Email
                                        </label>
                                        <InputText keyfilter="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="w-full" />
                                    </div>
                                    <div className='my-3'>
                                        <label htmlFor="gender" className="font-bold block mb-2">
                                            Gender
                                        </label>
                                        <Dropdown value={gender} options={genders} optionLabel="name" className="w-full" onChange={(e) => setGender(e.value)} />
                                    </div>
                                </div>

                                <div className="flex align-items-end justify-content-end m-2" style={{ paddingTop: 100 }}>
                                    <Button label="Save Changes" color="#005CE8" aria-label="Edit" onClick={onUpdate} />
                                </div>

                            </div>


                        </div>
                    </div>
                </>
            )}
        </Card>
    );
}

export default MyProfile;