

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { useMediaQuery } from 'react-responsive'
import Visa from '../../images/visa.png';
import Mastercard from '../../images/mastercard.png';
import Amex from '../../images/amex.png';
import { Checkbox } from 'primereact/checkbox';
import CheckoutForm from './Checkout';
import PaymentMethodModal from './PaymentMethodModal';
import { ListPaymentMethods, ListProducts, ListPrice, createSubscription, CreateCustomer, DeletePaymentMethod, ListSubscriptions, updateSubscription, updateCustomerDefaultPayment, SearchCustomer, CreateSetupIntent, cancelSubscription } from '../../api/Stripe';
import { getPlans } from '../../api/Plans';
import { Elements } from "@stripe/react-stripe-js";
import { ProgressSpinner } from 'primereact/progressspinner';
import { getOrganization } from '../../api/Organizations';
import WarningLogo from '../../images/warning.png';
import { AuthContext } from '../../types/authContext';
// import { Stepper } from 'primereact/stepper';
// import { StepperPanel } from 'primereact/stepperpanel';

export default function PaymentInfo(props) {
    const stepperRef = useRef(null);
    const user = useContext(AuthContext);
    const [view, setView] = useState("package");
    const toast = useRef(null);
    const stripePromise = props.stripePromise;
    const [customer, setCustomer] = useState(null);
    const [active, setActive] = useState(null);
    const [visible, setVisible] = useState(false);
    const [cancelVisible, setCancelVisible] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(true);
    const [upgradeInfo, setUpgradeInfo] = useState(null);
    const [secretType, setSecretType] = useState(null);
    const [clientSecret, setClientSecret] = useState(props.clientSecret);
    const [intentId, setIntentId] = useState("");
    const [products, setProducts] = useState([]);
    const [plans, setPlans] = useState([]);
    const [prices, setPrices] = useState([]);
    const [customerCards, setCustomerCards] = useState([]);
    const [defaultCard, setDefaultCard] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [addCardBtn, setAddCardBtn] = useState(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })


    const appearance = {
        theme: 'stripe' as const,
    };
    const options = {
        clientSecret,
        appearance,
    };
    useEffect(() => {
        setupProducts();
    }, []);


    const setupProducts = async () => {
        setLoading(true);
        const plns = await getPlans();

        const planData = plns?.docs?.map((item) => {
            if (item.data() && item.data().status == "active") {
                return { ...item.data(), id: item.id };
            }
        })
        let filteredData = [];
        planData.map(async (item: any) => {
            if (item && item.status == "active") {
                filteredData.push(item);
            }
        })
        const prod = await ListProducts();
        const pric = await ListPrice();
        setProducts(prod);
        setPrices(pric);
        setPlans(filteredData);
        const savedValue = user.auth;
        // const savedValue = JSON.parse(window.localStorage.getItem("auth"));
        let org = await getOrganization(savedValue.team.id);
        setOrganization(org);
        const customerdata = await SearchCustomer(savedValue.team.name);
        if (customerdata.data != "No data") {
            setCustomer(customerdata?.data.id);
            const subscriptions = await ListSubscriptions(customerdata?.data.id);
            setSubscription(subscriptions);
            filteredData && filteredData.map((p, index) => {
                if (subscriptions && p.id == subscriptions.plan.product) {
                    setActive(index);
                }
            })

            const payemethods = await ListPaymentMethods(customerdata?.data.id);
            setCustomerCards(payemethods);
            await payemethods && payemethods.map((p, index) => {
                if (subscriptions && p.id == subscriptions.default_payment_method) {
                    setDefaultCard(payemethods[index]);
                }
            })
        } else {
            setAddCardBtn(false);
        }



        // await prod && prod.map((p, index) => {
        //     if (subscriptions && p.id == subscriptions.items.data[0].price.product) {
        //         setActive(index);
        //     }
        // })


        setLoading(false);
    }

    const deleteCard = async (card) => {
        const data = await DeletePaymentMethod(card);
        if (data.id) {
            toast.current.show({ severity: 'info', summary: 'Success', detail: 'Payment method deleted successfully' });
        } else if (data.error) {
            toast.current.show({ severity: 'danger', summary: 'Error', detail: data.error });
        } else {
            toast.current.show({ severity: 'danger', summary: 'Error', detail: 'Something went wrong.' });
        }

        const payemethods = await ListPaymentMethods(customer);
        setCustomerCards(payemethods);
        setDefaultCard(payemethods && payemethods[0]);
    }

    const activatePlan = async (index, data) => {
        // const savedValue = JSON.parse(window.localStorage.getItem("auth"))
        const savedValue = user.auth;
        let subs = {};
        let customerdata = await SearchCustomer(savedValue.team.name);
        if (customerdata.data == "No data") {
            let org = await getOrganization(savedValue.team.id);
            const cusData = await CreateCustomer(savedValue.team.name, org.profile.email);
            setCustomer(cusData?.id);
            const subs = await createSubscription(data.priceId, cusData.id, 3);
            setClientSecret(subs.latest_invoice.payment_intent.client_secret);
            setIntentId(subs.id);
            setClientSecret(subs.latest_invoice.payment_intent.client_secret);
        } else {
            setCustomer(customerdata?.data.id);
            const subs = await createSubscription(data.priceId, customerdata.data.id, 3);
            setClientSecret(subs.latest_invoice.payment_intent.client_secret);
            setIntentId(subs.id);
            setClientSecret(subs.latest_invoice.payment_intent.client_secret);
        }
        setSecretType("payment");
        setView("checkout")
        // stepperRef.current.nextCallback();
        setActive(index);

    }

    const openDialog = async (index, data) => {
        setActive(index);
        setVisible(true);
        setUpgradeInfo(data);
    }

    const cancelSub = async () => {
        if (organization && organization.subscriptionId) {
            await cancelSubscription(organization.subscriptionId)
        }
        customerCards.map(async (car) => {
            await DeletePaymentMethod(car.id);
        })
        setCancelVisible(false);
        window.location.reload();
    }

    const updatePlanFromModal = async () => {
        if (upgradeInfo) {
            const subs = await updateSubscription(subscription.items.data[0]?.id, upgradeInfo.priceId);
            if (subs) {
                toast.current.show({ severity: 'info', summary: 'Success', detail: 'Plan changed successfully' });
            }
            setUpgradeInfo(null);
            setVisible(false);
        }
    }

    const changeDefaultCard = async (payment, index) => {
        if (customer) {
            setDefaultCard(customerCards[index]);
            const pay = await updateCustomerDefaultPayment(customer, subscription.id, payment);
            if (pay) {
                toast.current.show({ severity: 'info', summary: 'Success', detail: 'Default payment method changed successfully' });
            }
        }

    }

    const addNewCard = async () => {
        if (customer) {
            const subs = await CreateSetupIntent(customer);
            setClientSecret(subs.client_secret);
            setSecretType("newCard");
            setIntentId(subs.id);
            setView("checkout");
        }
    }

    return (
        <>
            <div className="card ">
                <Toast ref={toast}></Toast>
                {view === "package" ? (
                    <>
                        {loading ? (
                            <div className="flex align-items-center justify-content-center flex-wrap" >
                                <h1><ProgressSpinner /> </h1>
                            </div>
                        ) : (
                            <>
                                 {/* <div className="card flex flex-wrap justify-content-center align-content-center  m-5">


                                    <Stepper linear ref={stepperRef}>
                                        <StepperPanel header="Select Plan">

                                            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                                <div className="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
                                                <div className="text-700 text-xl mb-6 text-center line-height-3">Please select a plan from below.</div>

                                                <div className="grid">

                                                    {plans && plans.map((info, index) => (
                                                        <>
                                                            <div className="col-12 lg:col-6">
                                                                <div className="p-3 h-full">
                                                                    <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                                                                        <div className="text-900 font-medium text-xl mb-2">{info.name}</div>
                                                                        <div className="text-600">Plan description</div>
                                                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                                                        <div className="flex align-items-center">
                                                                            <span className="font-bold text-2xl text-900">{info.amountPerUser}</span>
                                                                            <span className="ml-2 font-medium text-600">per user</span>
                                                                        </div>
                                                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                                                            {info.features.map((feat) => (
                                                                                <li className="flex align-items-center mb-3">
                                                                                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                                                    <span className="text-900">{feat}</span>
                                                                                </li>
                                                                            ))}

                                                                        </ul>
                                                                        <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                                                        {subscription == null ? (<Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => activatePlan(index, info)} />) :
                                                                            index === active ? (<Button style={{ marginBottom: 5 }} severity='info' label="My Plan" color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)
                                                                                : index > active ? (<Button style={{ marginBottom: 5 }} outlined severity='info' label="Upgrade" color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)
                                                                                    : (<Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)}

                                                                        {index === active ? (<Button style={{ marginBottom: 5 }} severity='danger' label="Cancel Plan" color="#005CE8" aria-label="Edit" onClick={() => setCancelVisible(true)} />) : (<div />)}


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                    ))}

                                                </div>
                                            </div>

                                        </StepperPanel>
                                        <StepperPanel header="Add Card">
                                            <div className="flex flex-column h-17rem">
                                                <Elements options={options} stripe={stripePromise}>
                                                    <CheckoutForm clientSecret={clientSecret} intentId={intentId} secretType={secretType} setView={setView} cancelBtn={() => stepperRef.current.prevCallback()} />
                                                </Elements>
                                            </div>

                                        </StepperPanel>
                                        <StepperPanel header="Finalize">
                                            <div className="flex pt-4 justify-content-start">
                                                <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                                            </div>
                                        </StepperPanel>
                                    </Stepper>
                                </div> */}
                                <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                                    <div className="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
                                    <div className="text-700 text-xl mb-6 text-center line-height-3">Please select a plan from below.</div>

                                    <div className="grid">

                                        {plans && plans.map((info, index) => (
                                            <>
                                                <div className="col-12 lg:col-4"   >
                                                    <div className="p-3 h-full" >
                                                        <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                                                            <div className="text-900 font-medium text-xl mb-2" >{info.name}</div>
                                                            <div className="text-600">Plan description</div>
                                                            <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                                            <div className="flex align-items-center">
                                                                <span className="font-bold text-2xl text-900">${info.amountPerUser}</span>
                                                                <span className="ml-2 font-medium text-600">per user</span>
                                                            </div>
                                                            <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                                            <ul className="list-none p-0 m-0 flex-grow-1">
                                                                {info.features.map((feat) => (
                                                                    <li className="flex align-items-center mb-3">
                                                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                                        <span className="text-900">{feat}</span>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                            <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                                            {subscription == null ? (<Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => activatePlan(index, info)} />) :
                                                                index === active ? (<Button style={{ marginBottom: 5 }} severity='info' label="My Plan" color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)
                                                                    : index > active ? (<Button style={{ marginBottom: 5 }} outlined severity='info' label="Upgrade" color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)
                                                                        : (<Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)}

                                                            {index === active ? (<Button style={{ marginBottom: 5 }} severity='danger' label="Cancel Plan" color="#005CE8" aria-label="Edit" onClick={() => setCancelVisible(true)} />) : (<div />)}
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        ))}

                                    </div>
                                </div>



                                <div className='p-5' >

                                    <div className="flex justify-content-start">
                                        <Card style={{ width: '100%' }}>
                                            <h4>Update your payment method</h4>
                                            <p>Your plan payment details: </p>
                                            {customerCards.length > 0 && (
                                                customerCards.map((card, index) => (
                                                    <div className='flex justify-content-center m-1' >
                                                        {defaultCard && card.card.brand === defaultCard.card.brand && card.card === defaultCard.card ? (
                                                            <div className='m-2 p-2 grid' style={{ backgroundColor: "#E9EFFF", border: "1px solid", borderColor: "#1B59F9", borderRadius: 15, width: '100%' }}>
                                                                <div className=" col-2">
                                                                    <img alt="Card" src={card.card.brand === "visa" ? Visa : card.card.brand === "mastercard" ? Mastercard : card.card.brand === "amex" ? Amex : null} />


                                                                </div>
                                                                <div className="col-9">
                                                                    <b>{card.card.brand + " ending in " + card.card.last4}</b>
                                                                    <p>{"Expiry " + card.card.exp_month + ' / ' + card.card.exp_year}</p>
                                                                    <Button outlined text severity='secondary' onClick={() => changeDefaultCard(card.id, index)}>Set as default</Button>
                                                                    <Button outlined text severity='danger' onClick={() => deleteCard(card.id)}>Delete Card</Button>
                                                                </div>
                                                                <div className="col-1">
                                                                    <Checkbox onClick={() => changeDefaultCard(card.id, index)} checked={true}></Checkbox>
                                                                </div>


                                                            </div>
                                                        ) : (
                                                            <div className=' m-2 p-2 grid' style={{ border: "1px solid", borderColor: "#E4E7EC", borderRadius: 15, width: '100%' }}>
                                                                <div className="col-2">
                                                                    <img alt="Card" src={card.card.brand === "visa" ? Visa : card.card.brand === "mastercard" ? Mastercard : card.card.brand === "amex" ? Amex : null} />

                                                                </div>
                                                                <div className="col-9">
                                                                    <b>{card.card.brand + " ending in " + card.card.last4}</b>
                                                                    <p>{"Expiry " + card.card.exp_month + ' / ' + card.card.exp_year}</p>
                                                                    <Button outlined text severity='secondary' onClick={() => changeDefaultCard(card.id, index)}>Set as default</Button>
                                                                    <Button outlined text severity='danger' onClick={() => deleteCard(card.id)}>Delete Card</Button>
                                                                </div>
                                                                <div className="col-1">
                                                                    <Checkbox onClick={() => changeDefaultCard(card.id, index)} checked={false}></Checkbox>
                                                                </div>


                                                            </div>
                                                        )}

                                                    </div>
                                                ))
                                            )}
                                            <div className='flex justify-content-end m-1' >
                                                <Button disabled={!addCardBtn} onClick={() => addNewCard()}>Add new card</Button>
                                            </div>
                                        </Card>
                                    </div>



                                </div>


                            </>
                        )}
                    </>

                ) : view === "checkout" ? (
                    <>
                        {/* {clientSecret && ( */}
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm clientSecret={clientSecret} intentId={intentId} secretType={secretType} setView={setView} />
                        </Elements>
                        {/*   )}  */}
                    </>
                ) : (
                    <div className="card ">Else Payment</div>
                )}

                <Dialog visible={cancelVisible} style={{ width: '40vw' }} onHide={() => setCancelVisible(false)}>
                    <div className="flex text-center justify-content-center flex-wrap">
                        <img alt="Card" src={WarningLogo} /></div>

                    <div className="text-center justify-content-center font-bold border-round">
                        <h2 style={{ color: '#000766' }}>You are about to cancel your subscription</h2>
                        <p>Are you sure you want to cancel your subscription? This action can not be undone!</p>
                    </div>
                    <div className="flex justify-content-evenly flex-wrap m-2">

                        <Button label="No, go back" severity="info" text aria-label="Edit" onClick={() => setCancelVisible(false)} />
                        <Button label="Yes, Cancel" severity="danger" aria-label="Edit" onClick={() => cancelSub()} />
                    </div>
                </Dialog>
                <PaymentMethodModal isDesktopOrLaptop={isDesktopOrLaptop} visible={visible} setVisible={setVisible} customerCards={customerCards} defaultCard={defaultCard} addNewCard={addNewCard}
                    changeDefaultCard={changeDefaultCard} Visa={Visa} Mastercard={Mastercard} Amex={Amex} updatePlanFromModal={updatePlanFromModal} />
            </div>



        </>
    );
}
