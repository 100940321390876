
import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from 'primereact/dropdown';
import { updateOrg } from "../../api/Organizations";
import { updateUserData } from "../../api/Users";
import { useMediaQuery } from 'react-responsive'
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from "moment";
import { CreateCustomer } from "../../api/Stripe";
import { AuthContext } from "../../types/authContext";

type AdminModalProps = {
    status: boolean
    setStatus?: (value: boolean) => void
}

export default function UserDataModal(props: AdminModalProps ) {

    // const [selectedCity, setFilter] = useState(null);
    const genders = [
        { name: "Male" }, { name: "Female" }
    ]
    const userObj = useContext(AuthContext);
    const [authObj, setAuthObj] = useState({});
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [occupation, setOccupation] = useState('');
    const [message, setMessage] = useState('');
    const [gender, setGender] = useState(genders[0]);
    const [dob, setDOB] = useState('');
    const [error, setError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })
    useEffect(() => {
        setAuthObj(userObj.auth);
        // setAuthObj(JSON.parse(localStorage.getItem("auth")) ? JSON.parse(localStorage.getItem("auth")) : null)
    }, [userObj]);

    const addData = async () => {
        const savedvalue = JSON.parse(localStorage.getItem("auth") || '{}');
        // const savedvalue = userObj.auth;
        setMessage('');
        if (name.length == 0 || email.length == 0 || occupation.length == 0 || dob.length == 0 || gender == null || dateError) {
            setError(true);
        } else {
            setLoading(true);
            let obj = {
                name: name,
                email: email,
                occupation: occupation,
                gender: gender ? gender.name : null,
                dob: dob,

            }
            const uID = savedvalue.team.id + savedvalue.channel.slackUserId
            await updateUserData(obj, uID, savedvalue.channel.slackUserId, savedvalue.team.id).then((data) => {
                // if (data) {
                //     console.log(data)
                //     navigate("/templates");
                // } else {
                //     navigate("/");
                // }
                props.setStatus?.(false);
                window.location.reload();
                setLoading(false);
            })
            // console.log(data)
        }

    }

    const changeDOB = async (value : string) => {
        if (moment() < moment(value)) {
            setDateError(true);
        } else {
            setDateError(false)
            setDOB(value);
        }

    }

    const closePopUp = async () => {
        setMessage("Please take a minute and fill the form below in order to continue using our application..")
    }

    return (
        <div>
            <Dialog visible={props.status} style={{ width: isDesktopOrLaptop ? '50vw' : '90vw' }} onHide={() => closePopUp()}>

                <div>
                    {/* <h1 style={{ fontSize: isDesktopOrLaptop ? '3rem' : '1rem', marginTop: 0, color: '#005CE8' }}>Join for Success ✨</h1> */}
                    <h3>We’re excited to help you get started with our services. To ensure a smooth and secure process,
                        we need to collect some essential details from you. Please fill out the form below, and we’ll review your information promptly.</h3>
                    {isDesktopOrLaptop ? (

                        <div >
                            {message && (<small id="error">{message}</small>)}
                            <div className="formgrid grid" style={{ marginTop: isDesktopOrLaptop ? 30 : 20 }}>
                                <div className="field col">

                                    <div className='my-3'>
                                        <label htmlFor="name" className="font-bold block mb-2">
                                            Name
                                        </label>
                                        <InputText value={name} invalid={error && name.length == 0} placeholder="Name" onChange={(e) => setName(e.target.value)} className="w-full" />
                                        {error && name.length == 0 ? (
                                            <small id="error">
                                                Field can not be empty
                                            </small>
                                        ) : (<small />)}
                                    </div>
                                    <div className='my-3'>
                                        <label htmlFor="email" className="font-bold block mb-2">
                                            Email
                                        </label>
                                        <InputText keyfilter="email" value={email} invalid={error && email.length == 0} placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="w-full" />
                                        {error && email.length == 0 ? (
                                            <small id="error">
                                                Field can not be empty
                                            </small>
                                        ) : (<small />)}
                                    </div>
                                    <div className='my-3'>
                                        <label htmlFor="occupation" className="font-bold block mb-2">
                                            Occupation
                                        </label>
                                        <InputText value={occupation} invalid={error && occupation.length == 0} placeholder="Occupation" onChange={(e) => setOccupation(e.target.value)} className="w-full" />
                                        {error && occupation.length == 0 ? (
                                            <small id="error">
                                                Field can not be empty
                                            </small>
                                        ) : (<small />)}
                                    </div>


                                </div>
                                <div className="field col">

                                    <div className='my-3'>
                                        <label htmlFor="dob" className="font-bold block mb-2">
                                            Date of Birth
                                        </label>
                                        <InputMask mask="9999-99-99" value={dob} invalid={error && dob.length == 0 || dateError} placeholder="YYYY-MM-DD" className="w-full" onChange={(e) => changeDOB(e.target.value || '')} />
                                        {dateError ? (
                                            <small id="error">
                                                Invalid Date of Birth
                                            </small>
                                        ) : error && dob.length == 0 ? (
                                            <small id="error">
                                                Field can not be empty
                                            </small>
                                        ) : (<small />)}
                                    </div>
                                    <div className='my-3'>
                                        <label htmlFor="gender" className="font-bold block mb-2">
                                            Gender
                                        </label>
                                        <Dropdown value={gender} options={genders} optionLabel="name" className="w-full" onChange={(e) => setGender(e.value)} />
                                        {error && gender == null ? (
                                            <small id="error">
                                                Field can not be empty
                                            </small>
                                        ) : (<small />)}
                                    </div>

                                </div>

                            </div>
                            <div className="flex align-items-end justify-content-end" >
                                {loading ? (<p><ProgressSpinner style={{ width: '50px', height: '50px' }} /> </p>) : (
                                    <Button label="Submit" color="#005CE8" aria-label="Edit" onClick={addData} />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div >
                            {message && (<small id="error">{message}</small>)}
                            <div style={{ marginTop: isDesktopOrLaptop ? 50 : 35 }}>
                                <div className='my-3'>
                                    <label htmlFor="name" className="font-bold block mb-2">
                                        Name
                                    </label>
                                    <InputText value={name} invalid={error && name.length == 0} placeholder="Name" onChange={(e) => setName(e.target.value)} className="w-full" />
                                    {error && name.length == 0 ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}
                                </div>
                                <div className='my-3'>
                                    <label htmlFor="email" className="font-bold block mb-2">
                                        Email
                                    </label>
                                    <InputText keyfilter="email" value={email} invalid={error && email.length == 0} placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="w-full" />
                                    {error && email.length == 0 ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}
                                </div>
                                <div className='my-3'>
                                    <label htmlFor="occupation" className="font-bold block mb-2">
                                        Occupation
                                    </label>
                                    <InputText value={occupation} invalid={error && occupation.length == 0} placeholder="Occupation" onChange={(e) => setOccupation(e.target.value)} className="w-full" />
                                    {error && occupation.length == 0 ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}
                                </div>
                                <div className='my-3'>
                                    <label htmlFor="dob" className="font-bold block mb-2">
                                        Date of Birth
                                    </label>
                                    <InputMask mask="9999-99-99" value={dob} invalid={error && dob.length == 0} placeholder="YYYY-MM-DD" className="w-full" onChange={(e) => setDOB(e.target.value || '')} />
                                    {dateError ? (
                                        <small id="error">
                                            Invalid Date of Birth
                                        </small>
                                    ) : error && dob.length == 0 ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}
                                </div>

                                <div className='my-3'>
                                    <label htmlFor="gender" className="font-bold block mb-2">
                                        Gender
                                    </label>
                                    <Dropdown value={gender} options={genders} optionLabel="name" className="w-full" onChange={(e) => setGender(e.value)} />
                                    {error && gender == null ? (
                                        <small id="error">
                                            Field can not be empty
                                        </small>
                                    ) : (<small />)}
                                </div>
                                <div className="flex align-items-start justify-content-start my-3" >
                                    {loading ? (<p><ProgressSpinner style={{ width: '50px', height: '50px' }} /> </p>) : (
                                        <Button label="Submit" color="#005CE8" aria-label="Edit" onClick={addData} />
                                    )}


                                </div>
                            </div>
                        </div>
                    )}
                </div>



            </Dialog>
        </div>

    );
}