import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./pages/Layout";
import Billing from "./pages/Billing/Billing";
import BillingWizard from "./pages/Billing/BillingWizard";
import BillingSucess from "./pages/Billing/BillingSucess";
import BillingFailed from "./pages/Billing/BillingFailed";
import BillingComplete from "./pages/Billing/CompletePage";
import NewCard from "./pages/Billing/CompleteCardSetup";
import MyTemplates from "./pages/MyWorkflows";
import SlackSignIn from "./pages/SlackSignIn";
import Auth from "./pages/Auth";
import Template from "./pages/Template/Template";
import EditTemplate from "./pages/EditTemplate/EditTemplate";
import NewTemplate from "./pages/NewTemplate/NewTemplate";
import ComingSoon from "./pages/Common/ComingSoon";
import Page404 from "./pages/Common/Page404";
import TrialExpired from "./pages/Common/TrialExpired";
import ExceedUserCount from "./pages/Common/ExceedUserCount";
import Profile from "./pages/Profile/Profile";
import Members from "./pages/Members/Members"
import Checkout from "./pages/Billing/Checkout";
import Approval from "./pages/Approval";
import Logs from "./pages/Logs/logs";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="sign-in" element={<SlackSignIn />} />
          <Route path="auth" element={<Auth />} />
          <Route path="templates" element={<Dashboard />} />
          <Route path="billing" element={<Billing />} />
          <Route path="billing/new" element={<BillingWizard />} />
          <Route path="billing/success" element={<BillingSucess />} />
          <Route path="billing/failed" element={<BillingFailed />} />
          <Route path="approval" element={<Approval />} />
          <Route path="billing/payment" element={<BillingComplete />} />
          <Route path="billing/new-card" element={<NewCard />} />
          <Route path="billing/checkout" element={<Checkout />} />
          <Route path="trial-expired" element={<TrialExpired />} />
          <Route path="help" element={<ComingSoon />} />
          <Route path="profile" element={<Profile />} />
          <Route path="members" element={<Members />} />
          <Route path="logs" element={<Logs />} />
          <Route path="user-exceed" element={<ExceedUserCount />} />
          <Route path="workflows" element={<MyTemplates />} />
          <Route path="workflows/:id" element={<Template />} />
          <Route path="new-template/:preset" element={<NewTemplate />} />
          <Route path="edit-template/:id" element={<EditTemplate />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);