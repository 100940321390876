import React, { createContext, useState } from 'react';

// Create the context
export const FlowContext = createContext(null);

// Create a provider component
export const FlowProvider = ({ children }) => {
//   const [flows, setFlows] = useState(JSON.parse(window.localStorage.getItem("flowList")) || null);
const [flows, setFlows] = useState(null) ;

  // Function to toggle the login state
  // const toggleLogin = () => {
  //   setFlows((prevState) => ({
  //     ...prevState,
  //     // loggedIn: !prevState.loggedIn,
  //   }));
  // };

  return (
    <FlowContext.Provider value={{ flows, setFlows }}>
      {children}
    </FlowContext.Provider>
  );
};