import {
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc,
    orderBy,
    startAfter,
    getCountFromServer,
    limit
} from "firebase/firestore";
import { db } from "../firebaseConfig";

const moment = require("moment");

export const getBarChartData = async (params: any, range: any) => {
    try {
        if (range == "today") {
            let startDate = moment().format("YYYY-MM-DD 00:00");
            let endDate = moment().format("YYYY-MM-DD 23:59");
            let totalHours = parseInt(moment(endDate).diff(moment(startDate), 'hours'));

            let first = null;
            first = query(collection(db, "answers", String(params), "userAnswers"), where("createdAt", ">=", startDate), where("createdAt", "<", endDate), orderBy('createdAt', 'asc'));
    
            let dataArr: object[] = [];
            let max = 0;
            const querySnapshot = await getDocs(first);
            querySnapshot.forEach((doc : any) => {
                let a = {
                    userId: doc.data().userId ,
                    createdAt: moment(doc.data().createdAt).format("YYYY-MM-DD HH:mm"),
                }
                dataArr.push(a);
            });
    
    
    
            let DateArr = [];
            let countArr: number[] = [];
            for (let i = 0; i <= totalHours; i++) {
                DateArr.push(moment(startDate).add(i, "hours").format("YYYY-MM-DD HH:mm"));
            }
    

            DateArr.map((date) => {
                const count = dataArr.filter((obj : any) => moment(obj.createdAt).format("HH") === moment(date).format("HH")).length;
                countArr.push(count);
            })
    
            let formattedDateArr = DateArr.map((date) => {
                return moment(date).format("hh:mm a");
            })
            countArr && countArr.map(d => {
                max = Math.max(max, d)
            })
            // const count = dataArr.filter((obj) => obj.createdAt === id).length;
    
            let finalObj = {
                labels: formattedDateArr,
                datasets: [
                    {
                        label: 'Partcicipation',
                        data: countArr,
                        backgroundColor: [
                            '#005CE8'
                        ],
                        borderColor: [
                            '#005CE8'
                        ],
                        borderWidth: 1,
                        borderRadius: 6,
                        maxBarThickness: 30
                    }
                ],
                maxValue: max
            }
    
            return finalObj;
        }else if (range.key && (range.key == "this week" || range.key == "this month")  ) {
            let startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
            let endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
            let totalDays = parseInt(moment(endDate).diff(moment(startDate), 'days'));
    
    
            let first = null;
            first = query(collection(db, "answers", String(params), "userAnswers"), where("createdAt", ">=", startDate), where("createdAt", "<", endDate), orderBy('createdAt', 'asc'));
    
            let dataArr: any[] = [];
            let max = 0;
            const querySnapshot = await getDocs(first);
            querySnapshot.forEach((doc: any) => {
                let a = {
                    userId: doc.data().userId,
                    createdAt: moment(doc.data().createdAt).format("YYYY-MM-DD"),
                }
                dataArr.push(a);
            });
    
    
    
            let DateArr = [];
            let countArr: number[] = [];
            for (let i = totalDays; i >= 0; i--) {
                DateArr.push(moment(endDate).subtract(i, "days").format("YYYY-MM-DD"));
            }
    
            DateArr.map((date) => {
                const count = dataArr.filter((obj) => obj.createdAt === date).length;
                countArr.push(count);
            })
    
            let formattedDateArr = DateArr.map((date) => {
                return moment(date).format("MMM Do");
            })
    
            countArr && countArr.map(d => {
                max = Math.max(max, d)
            })
            // const count = dataArr.filter((obj) => obj.createdAt === id).length;
    
            let finalObj = {
                labels: formattedDateArr,
                datasets: [
                    {
                        label: 'Partcicipation',
                        data: countArr,
                        backgroundColor: [
                            '#005CE8'
                        ],
                        borderColor: [
                            '#005CE8'
                        ],
                        borderWidth: 1,
                        borderRadius: 6,
                        maxBarThickness: 30
                    }
                ],
                maxValue: max
            }
    
            return finalObj;
        } else{
            let startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
            let endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
            let totalMonths = parseInt(moment(endDate).diff(moment(startDate), 'months'));
    
    
            let first = null;
            first = query(collection(db, "answers", String(params), "userAnswers"), where("createdAt", ">=", startDate), where("createdAt", "<", endDate), orderBy('createdAt', 'asc'));
    
            let dataArr: any[] = [];
            let max = 0;
            const querySnapshot = await getDocs(first);
            querySnapshot.forEach((doc: any) => {
                let a = {
                    userId: doc.data().userId,
                    createdAt: moment(doc.data().createdAt).format("YYYY-MM-DD"),
                }
                dataArr.push(a);
            });

    
            let DateArr = [];
            let countArr: number[] = [];
            for (let i = totalMonths - 1; i >= 0; i--) {
                DateArr.push(moment(endDate).subtract(i, "months").format("YYYY-MM-DD"));
            }
    
            DateArr.map((date) => {
                const count = dataArr.filter((obj) => new Date(obj.createdAt).getMonth()  === new Date(date).getMonth()).length;
                countArr.push(count);
            })
    
            let formattedDateArr = DateArr.map((date) => {
                return moment(date).format("MMMM");
            })
    
            countArr && countArr.map(d => {
                max = Math.max(max, d)
            })
            // const count = dataArr.filter((obj) => obj.createdAt === id).length;
    
            let finalObj = {
                labels: formattedDateArr,
                datasets: [
                    {
                        label: 'Partcicipation',
                        data: countArr,
                        backgroundColor: [
                            '#005CE8'
                        ],
                        borderColor: [
                            '#005CE8'
                        ],
                        borderWidth: 1,
                        borderRadius: 6,
                        maxBarThickness: 30
                    }
                ],
                maxValue: max
            }
    
            return finalObj;
        }
        




    } catch (error) {
        console.error("Error retreving document: ", error);
    }
};

export const getPieChartData = async (params: string) => {
    try {
        let startDate = moment().startOf('day').format("YYYY-MM-DD 00:00");
        let endDate = moment().endOf('day').format("YYYY-MM-DD 23:59");



        let first = null;
        first = query(collection(db, "answers", String(params), "userAnswers"), where("createdAt", ">=", startDate), where("createdAt", "<", endDate), orderBy('createdAt', 'desc'));

        const docRef = doc(db, "flows", params);
        let part = [];
        let flow = await getDoc(docRef);
        if (flow?.exists()) {
            part = flow.data().participants;
            const snapshot = await getCountFromServer(first);
            const count = snapshot.data().count;
            const percentage = (count / (1 * part.length)) * 100;
            let finalObj = {
                percentage: percentage,
                chart: "pie",
                participants: part.length,
                count: count
            }

            return finalObj;
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }




    } catch (error) {
        console.error("Error retreving document: ", error);
    }
};

