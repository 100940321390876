import React, { useContext, useEffect, useState } from 'react';
// import { TabView, TabPanel } from 'primereact/tabview';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Topbar from "../../components/Topbar";
import InvoicesTable from './InvoicesTable';
import PaymentInfo from './PaymentInfo';
import { SearchCustomer, ListInvoices } from '../../api/Stripe';
import { loadStripe } from "@stripe/stripe-js";
import { Card } from 'primereact/card';
import { AuthContext } from '../../types/authContext';

const moment = require("moment");


export default function Billing() {
  const user = useContext(AuthContext);
  // const savedValue = JSON.parse(window.localStorage.getItem("auth"));
  const savedValue = user.auth;
  // const [customer, setCustomer] = useState("");
  const [invoices, setInvoices] = useState([]);
  const stripePromise = loadStripe("pk_test_WRr6k7JXHNlcbgVpTDKSHp7p");
  useEffect(() => {
    setUpIntent();
  }, []);

  const setUpIntent = async () => {
    const customerdata = await SearchCustomer(savedValue.team.name);
    // setCustomer(customerdata?.data.id);

    if (customerdata.data != "No data") {
      const inv = await ListInvoices(customerdata?.data.id);
      let filtered = [];
      await inv.map((data) => {
        let x = {
          createdAt: moment(new Date(data.created * 1000)).format("YYYY-MM-DD hh:mm a"),
          number: data.number,
          total: "$ " + String(data.total).slice(0, -2) + ".00",
          status: data.status == "open" ? 'failed' : data.status == "void" || data.status == "uncollectible" ? 'unpaid' : data.status,
        }
        filtered.push(x);
      })
      setInvoices(filtered)

    }


  }


  return (
    <>
      <div>
        <div> <Topbar title={"Billing"} /></div>
        <script src="https://js.stripe.com/v3/"></script>
      </div>
      <Card className="card mx-5 my-5 px-4 ">
        <Tabs>
          <TabList>
            <Tab><h4>Payment Information</h4></Tab>
            <Tab><h4>Invoices</h4></Tab>
          </TabList>
          <TabPanel>
            <PaymentInfo
              stripePromise={stripePromise}
            />
          </TabPanel>
          <TabPanel>
            <InvoicesTable invoices={invoices} />
          </TabPanel>
        </Tabs>
        {/* <TabView>
          <TabPanel header="Payment Information">

            <PaymentInfo
              stripePromise={stripePromise}
              />

          </TabPanel>
          <TabPanel header="Invoices">
            <InvoicesTable invoices={invoices} />
          </TabPanel>
        </TabView> */}
      </Card>

    </>

  )
}
