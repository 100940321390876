
import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Card } from "primereact/card";
import Waiting from "../images/register.jpg";
import Waiting2 from "../images/waiting2.jpg";
import Logo from "../images/logoCropped2.png";
import { updateOrg } from "../api/Organizations";
import { updateUserData } from "../api/Users";
import { useMediaQuery } from 'react-responsive'
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { authObjType } from "../types/types";
import { AuthContext } from "../types/authContext";

export default function Approval(props) {
    // const [selectedCity, setFilter] = useState(null);
    const { status } = props
    const user = useContext(AuthContext);
    const genders = [
        { name: "Male" }, { name: "Female" }
    ]
    const [authObj, setAuthObj] = useState<authObjType>();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [occupation, setOccupation] = useState('');
    const [gender, setGender] = useState(genders[0]);
    const [dob, setDOB] = useState('');
    const [error, setError] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })
    useEffect(() => {
        // setTimeout(() => {
        // setAuthObj(JSON.parse(localStorage.getItem("auth")) ? JSON.parse(localStorage.getItem("auth")) : null)
        setAuthObj(user?.auth);
        // }, 1000);
    }, []);

    const addData = async () => {
        if (name.length == 0 || email.length == 0 || occupation.length == 0 || dob.length == 0 || gender == null) {
            setError(true);
        } else {
            let obj = {
                name: name,
                email: email,
                occupation: occupation,
                gender: gender ? gender.name : null,
                dob: dob,

            }
            const uID = authObj.team.id + authObj.channel.slackUserId
            await updateUserData(obj, uID, authObj.channel.slackUserId, authObj.team.id);
            await updateOrg(obj, authObj.team.id, authObj ).then((data) => {
                // if (data) {
                //     console.log(data)
                //     navigate("/templates");
                // } else {
                //     navigate("/");
                // }
                window.location.reload();
            })
            // console.log(data)
        }

    }



    return (
        <Card >

            <div className="flex flex-wrap" style={{ height: "100vh" }}>

                <>
                    {status == "null" ? (
                        <div className="flex flex-wrap">
                            <div className="px-5" style={{ width: isDesktopOrLaptop ? '55%' : '100%', }}>
                                <img alt="waiting" src={Logo} width={'20%'} style={{ zIndex: 15, }} />
                                <div >
                                    {/* <h1 style={{ fontSize: isDesktopOrLaptop ? '5rem' : '3rem', marginTop: 0, color: '#005CE8' }}>Join for Success ✨</h1> */}

                                    <h3>We’re excited to help you get started with our services. To ensure a smooth and secure process,
                                        we need to collect some essential details from you. Please fill out the form below, and we’ll review your information promptly.</h3>
                                    <div >
                                        <div style={{ marginTop: isDesktopOrLaptop ? 50 : 35 }}>
                                            <div className='my-3'>
                                                <label htmlFor="name" className="font-bold block mb-2">
                                                    Name
                                                </label>
                                                <InputText value={name} invalid={error && name.length == 0} placeholder="Name" onChange={(e) => setName(e.target.value)} className="w-full" />
                                                {error && name.length == 0 ? (
                                                    <small id="error">
                                                        Field can not be empty
                                                    </small>
                                                ) : (<small />)}
                                            </div>
                                            <div className='my-3'>
                                                <label htmlFor="email" className="font-bold block mb-2">
                                                    Email
                                                </label>
                                                <InputText keyfilter="email" value={email} invalid={error && email.length == 0} placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="w-full" />
                                                {error && email.length == 0 ? (
                                                    <small id="error">
                                                        Field can not be empty
                                                    </small>
                                                ) : (<small />)}
                                            </div>
                                            <div className='my-3'>
                                                <label htmlFor="occupation" className="font-bold block mb-2">
                                                    Occupation
                                                </label>
                                                <InputText value={occupation} invalid={error && occupation.length == 0} placeholder="Occupation" onChange={(e) => setOccupation(e.target.value)} className="w-full" />
                                                {error && occupation.length == 0 ? (
                                                    <small id="error">
                                                        Field can not be empty
                                                    </small>
                                                ) : (<small />)}
                                            </div>
                                            <div className='my-3'>
                                                <label htmlFor="dob" className="font-bold block mb-2">
                                                    Date of Birth
                                                </label>
                                                <InputMask mask="9999-99-99" value={dob} invalid={error && dob.length == 0} placeholder="YYYY-MM-DD" className="w-full" onChange={(e) => setDOB(e.target.value)} />
                                                {error && dob.length == 0 ? (
                                                    <small id="error">
                                                        Field can not be empty
                                                    </small>
                                                ) : (<small />)}
                                            </div>

                                            <div className='my-3'>
                                                <label htmlFor="gender" className="font-bold block mb-2">
                                                    Gender
                                                </label>
                                                <Dropdown value={gender} options={genders} optionLabel="name" className="w-full" onChange={(e) => setGender(e.value)} />
                                                {error && gender == null ? (
                                                    <small id="error">
                                                        Field can not be empty
                                                    </small>
                                                ) : (<small />)}
                                            </div>
                                            <div className="flex align-items-start justify-content-start my-3" >
                                                <Button label="Submit" color="#005CE8" aria-label="Edit" onClick={addData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center px-3" style={{ width: isDesktopOrLaptop ? '45%' : '100%', }}>
                                <img alt="waiting" src={Waiting} width={'100%'} style={{ zIndex: 15, }} />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-wrap">
                            <div className="px-5" style={{ width: isDesktopOrLaptop ? '55%' : '100%',  }}>
                            <img alt="waiting" src={Logo} width={'20%'} style={{ zIndex: 15, }} />
                            <div > 
                                <h1 style={{ fontSize: isDesktopOrLaptop ? '5rem' : '3rem', marginTop: 0, color: '#005CE8' }}>Thank You for Your Submission!</h1>
                                
                                <h3 >We appreciate you taking the time to provide your details. Your request has been received and is now under review. Here’s what you can expect next:</h3>

                                <div style={{ marginTop: isDesktopOrLaptop ? 70 : 35 }}>
                                    <h3 style={{ color: "#005CE8" }}>What Happens Now?</h3>
                                    <ol>
                                        <h3>1. Review Process: Our team is currently reviewing the information you’ve submitted to ensure everything meets our approval criteria.</h3>
                                        <h3>2. Approval Time: This process typically takes 1-2 business days. We appreciate your patience during this time.</h3>
                                        <h3>3. OnBoard: After you request has been approved, you can get onboard immediately</h3>
                                    </ol>
                                </div>
                                <div style={{ marginTop: isDesktopOrLaptop ? 70 : 35 }}>
                                    <h3>
                                        We’re committed to providing you with the best service and will get back to you as soon as possible. Thank you for your patience and understanding!
                                    </h3>
                                </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center px-5" style={{ width: isDesktopOrLaptop ? '45%' : '100%', }}>
                                <img alt="waiting" src={Waiting2} width={'100%'} style={{ zIndex: 15, }} />
                            </div>
                        </div>
                    )}

                </>


            </div>


        </Card>

    )
}
