import React, { createContext, useState } from 'react';

// Create the context
export const ChannelContext = createContext(null);

// Create a provider component
export const ChannelProvider = ({ children }) => {
  const [channels, setChannels] = useState(JSON.parse(window.localStorage.getItem("channelList")) || null);

  // Function to toggle the login state
  // const toggleLogin = () => {
  //   setChannels((prevState) => ({
  //     ...prevState,
  //     // loggedIn: !prevState.loggedIn,
  //   }));
  // };

  return (
    <ChannelContext.Provider value={{ channels, setChannels }}>
      {children}
    </ChannelContext.Provider>
  );
};