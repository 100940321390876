import React, { createContext, useState } from 'react';

// Create the context
export const TemplateContext = createContext(null);

// Create a provider component
export const TemplateProvider = ({ children }) => {
//   const [templates, setTemplates] = useState(JSON.parse(window.localStorage.getItem("flowList")) || null);
const [templates, setTemplates] = useState(null) ;

  // Function to toggle the login state
  // const toggleLogin = () => {
  //   setTemplates((prevState) => ({
  //     ...prevState,
  //     // loggedIn: !prevState.loggedIn,
  //   }));
  // };

  return (
    <TemplateContext.Provider value={{ templates, setTemplates }}>
      {children}
    </TemplateContext.Provider>
  );
};