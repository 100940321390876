import React, { useState, useEffect, useContext } from 'react';
import Topbar from "../../components/Topbar";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { FilterMatchMode } from 'primereact/api';
import { InputIcon } from 'primereact/inputicon';
import { logsPresentById } from "../../api/Logs";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import Page403 from '../Common/Page403';
import { getUser } from "../../api/Users";
import { Button } from 'primereact/button';
import { user } from '../../types/types';
import { Card } from 'primereact/card';
import { AuthContext } from '../../types/authContext';
import { ParticipantContext } from '../../types/participantContext';
const moment = require("moment");

export default function Logs() {
    const user = useContext(AuthContext);
    const participantList = useContext(ParticipantContext);
    // const part = JSON.parse(window.localStorage.getItem("participantList") || '{}');
    const part = participantList.participants;
    const [logs, setLogs] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [dates, setDates] = useState(null);
    const [userData, setUserData] = useState<user>();
    const savedValue = user.auth;
    // const savedValue = JSON.parse(window.localStorage.getItem("auth") || '{}');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        message: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        updatedBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);


        getTemplates(savedValue.team.id, null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getTemplates = async (teamId: any, timestamp: { startDate: string; endDate: string; key: string; } | null) => {
        const logData = await logsPresentById(teamId, timestamp);
        const user = await getUser(savedValue.team.id + savedValue.channel.slackUserId);
        setUserData(user as user);
        let formattedData: any = [];
        logData.map((data) => {
            let string = data.message;
            let myMatches = string.match(/<.*>/g);
            let message = '';
            if (myMatches) {
                let myMatch = myMatches[0].slice(1).slice(0, -1);
                let name = part[myMatch]?.profile?.real_name
                let newstring = string.replace('<' + myMatch + '>', name);
                message = newstring;
            }
            else {
                message = data.message
            }
            let a = {
                id: data.id,
                timestamp: data.timestamp,
                type: data.type,
                teamId: data.teamId,
                status: data.status,
                createdBy: data.createdBy == "scheduler" ? data.createdBy : data.createdBy == "System" || data.createdBy == "Stripe" ? data.createdBy : part[data.createdBy]?.profile?.real_name,
                updatedBy: data.updatedBy == "-" ? data.updatedBy : part[data.updatedBy]?.profile?.real_name,
                message: message,
                flowId: data.flowId
            }
            formattedData.push(a);
        })
        setLogs(formattedData);
        setLoading(false);
    };



    const onGlobalFilterChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // const userMarkupCreatedBy = (rowData) => {
    //     if(rowData.createdBy == "scheduler"){
    //         return rowData.createdBy
    //     }else{
    //         return (
    //             part[rowData.createdBy]?.profile?.real_name
    //         );
    //     }

    // };

    // const userMarkupUpdatedBy = (rowData) => {
    //     if(rowData.updatedBy == "-"){
    //         return rowData.updatedBy
    //     }else{
    //         return (
    //             part[rowData.updatedBy]?.profile?.real_name
    //         );
    //     }

    // };

    const messageMarkup = (rowData: { flowId: string; message: string; }) => {
        if (rowData.flowId) {

            let myMatches2 = rowData.message.match(/%.*%/g);
            if (myMatches2) {
                let myMatch = myMatches2[0].slice(1).slice(0, -1);
                let newstring = rowData.message.replace('%' + myMatch + '%', " ");
                return (
                    <>
                        <Link to={"/workflows/" + rowData.flowId} style={{ textDecoration: 'none', color: '#005CE8' }}>
                            {myMatch}
                        </Link>
                        {" " + newstring}
                    </>
                )
            } else {
                return rowData.message;
            }

        } else if (rowData.message) {
            return rowData.message;
        } else {
            return "-";
        }

    };


    const timeMarkup = (rowData: { timestamp: any; }) => {
        if (rowData.timestamp) {
            return moment(rowData.timestamp).format("DD/MM/YYYY hh:mm A")
        } else {
            return "-";
        }

    };

    // const messageMarkup = (rowData) => {
    //         let string = rowData.message;
    //     let myMatches = string.match(/<.*>/g);
    //     if(myMatches){
    //         let myMatch = myMatches[0].slice(1).slice(0,-1);
    //         let name = part[myMatch]?.profile?.real_name
    //         let newstring = string.replace('<' + myMatch + '>', name);
    //         return newstring;
    //     }
    //     else{
    //         return rowData.message
    //     }


    // };

    const handleDateChanged = (date: any) => {
        setDates(date);
        // const savedValue = JSON.parse(window.localStorage.getItem("participantList") || '{}');
        if (date == null) {
            setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                message: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                updatedBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                createdBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                // representative: { value: null, matchMode: FilterMatchMode.IN },
                status: { value: null, matchMode: FilterMatchMode.EQUALS },
            });
            setGlobalFilterValue('');
            getTemplates(savedValue.team.id, null);
        } else {

            let obj = {
                startDate: moment(date[0]).format("YYYY-MM-DD") + " " + "00:00",
                endDate: moment(date[1]).format("YYYY-MM-DD") + " " + "23:59",
                key: 'selection',
            }
            getTemplates(savedValue.team.id, obj);
        }

    }


    let maxDate = new Date();

    const renderHeader = () => {
        return (
            <div className="px-2 py-1">

                <div className="flex justify-content-end">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </IconField>
                    <div className="px-2" style={{ width: 'max-content' }} >
                        <Calendar style={{ width: 'max-content' }} value={dates} placeholder="Timestamp Range" onChange={(e) => handleDateChanged(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection maxDate={maxDate} />
                        <Button disabled={globalFilterValue == '' && dates == null} className='mx-2' onClick={() => handleDateChanged(null)}>Reset</Button>
                    </div>
                </div>
            </div>
        );
    };
    const header = renderHeader();


    return (
        <>
            {userData && userData.userType == "user" ? (<Page403 />) : (
                <>
                    <div>
                        <div> <Topbar title={"Logs"} /></div>
                    </div>
                    {loading ? (
                        <div className="flex align-items-center justify-content-center flex-wrap" >
                            <h1><ProgressSpinner /> </h1>
                        </div>
                    ) : (
                        <div className="card mx-5 my-5 ">
                            <div className="mx-5 px-5">
                            <DataTable value={logs} paginator rows={15} dataKey="id" filters={filters} filterDisplay="row" loading={loading}
                                globalFilterFields={[, 'message', 'status']} header={header} emptyMessage="No logs found.">
                                <Column header="Notification" field="message" body={messageMarkup} />
                                <Column field="createdBy" header="Owner" />
                                <Column field="updatedBy" header="Updated By" />
                                <Column field="status" header="Status" sortable />
                                <Column field="timestamp" header="Timestamp" sortable body={timeMarkup} />
                            </DataTable>
                            </div>
                            
                        </div>
                    )}


                </>
            )}
        </>


    )
}