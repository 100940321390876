import React, { useState, useEffect } from "react";
import { Calendar } from 'primereact/calendar';
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { AvatarGroup } from 'primereact/avatargroup';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import WeeksArray from "../../components/WeeksArray"
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
const moment = require("moment");


function TemplateDetails(props) {

    const questions = [
        "How do you feel today?",
        "What did you do yesterday?",
        "What will you do today?",
        "Anything blocking your progress?"
    ]

    const Members = [
        { name: "Brooklyn Simmons", email: "ateniese@mac.com" },
        { name: "Ralph Edwards", email: "raines@optonline.com" },
        { name: "Arlene McCoy", email: "kspiteri@live.com" },
        { name: "Jane Cooper", email: "mthurn@optonline.com" }
    ]

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [dates, setDates] = useState([new Date(), tomorrow]);
    const [showAll, setShowAll] = useState(false);
    const [lessParticipants, setLessParticipants] = useState(null);
    // const [loading, setLoading] = useState(false);
    const { flow } = props;
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })
    // const { id } = props;
    // const { savedValue } = props;

    useEffect(() => {
        if (props.flow && props.flow.schedule == 'Custom') {
            let dateArr = props.flow.customDates.map((day) => {
                return new Date(day);
            })
            setDates(dateArr);
        };
        const sortedParticipant = props.flow && props.flow.participants.map((p, index) => {
            if (index < 5) {
                return p;
            }
        })
        setLessParticipants(sortedParticipant);
    }, []);





    return (
        <>
            <Card>
                <div className="grid">
                    <div className="col">
                        <div className="px-3 border-round-sm font-bold">
                            <MediaQuery maxWidth={470}>
                                <div className="flex justify-content-end flex-wrap m-4">

                                    <Link to={'/edit-template/' + props.id} style={{ textDecoration: 'none', }}>

                                        <Button icon="pi pi-file-edit" rounded label="Edit" severity="secondary" aria-label="Edit" /></Link>
                                </div>
                            </MediaQuery>
                            <h2>
                                {flow?.title}
                            </h2>
                            <div className="grid">
                                <div className="col">
                                    <div className="row">
                                        <p className="px-0 py-0 m-0" style={{ fontWeight: 200, color: '#959FA3' }}>
                                            TIME
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p className="px-0 py-0 my-1">
                                            {moment("2024-01-01 " + flow?.time).format('LT')}
                                        </p>
                                    </div>


                                </div>
                                <div className="col">
                                    <div className="col">
                                        <div className="row">
                                            <p className="px-0 py-0 m-0" style={{ fontWeight: 200, color: '#959FA3' }}>
                                                TIMEZONE
                                            </p>
                                        </div>
                                        <div className="row">
                                            <p className="px-0 py-0 my-1">
                                                {flow?.timezone}
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </div>




                            <div className="row">
                                <h3 >
                                    Questions:
                                </h3>
                            </div>



                            {flow?.questions?.map((question, index) => (
                                <div className="flex row">

                                    <div className="m-2" style={{ width: 10, height: 10, backgroundColor: props.colors[index % 4] }}></div>

                                    <p className="px-0 py-0 my-1">
                                        {question}
                                    </p>
                                </div>
                            ))}

                            <div className="my-5">
                                <h3 style={{ marginBottom: 0, }}>Members ({flow?.participants?.length})</h3>
                                {flow?.participants?.length >= 5 ? (
                                    <Button onClick={() => setShowAll(!showAll)} style={{ padding: 1 }} text severity="secondary" aria-label="Bookmark" >
                                        <h6 style={{ margin: 1, fontWeight: 200, color: '#959FA3' }}>{showAll ? 'Show less' : 'Show all'}</h6> </Button>
                                ) : <div />}

                                {showAll ? (
                                    <div className="flex flex-wrap " >
                                        {flow?.participants?.map((member) => (
                                            <div className="flex wrap align-items-center justify-content-center font-bold px-3 gap-3 border-round" style={isDesktopOrLaptop ? { minHeight: 100 } : {}}>

                                                <div>
                                                    <Avatar label={props?.participantObj.length && props?.participantObj[member]?.profile ? props?.participantObj[member]?.profile?.real_name[0] : props?.participantObj[member]?.real_name[0]}
                                                        style={{ backgroundColor: props.participantObj && props.participantObj[member].color, color: '#ffffff' }} shape="circle" size="large" />
                                                </div>
                                                <div>
                                                    <h5>{props?.participantObj.length && props?.participantObj[member]?.profile ? props?.participantObj[member]?.profile?.real_name : props?.participantObj[member]?.real_name}</h5>
                                                    {/* <p style={{ fontSize: 14, color: "#7B878C", marginTop: 0 }} >{member.email}</p> */}
                                                </div>
                                            </div>

                                        ))}


                                    </div>
                                ) : (<div className="flex flex-wrap " >
                                    {lessParticipants && lessParticipants?.map((member) => (
                                        <div className="flex align-items-center justify-content-center font-bold px-3 gap-3 border-round" style={isDesktopOrLaptop ? { minHeight: 100 } : {}}>

                                            <div>
                                                <Avatar label={props?.participantObj.length && props?.participantObj[member]?.profile ? props?.participantObj[member]?.profile?.real_name[0] : props?.participantObj[member]?.real_name[0]}
                                                    style={{ backgroundColor: props.participantObj && props.participantObj[member].color, color: '#ffffff' }} shape="circle" size="large" />
                                            </div>
                                            <div>
                                                <h5>{props?.participantObj.length && props?.participantObj[member]?.profile ? props?.participantObj[member]?.profile?.real_name : props?.participantObj[member]?.real_name}</h5>
                                                {/* <p style={{ fontSize: 14, color: "#7B878C", marginTop: 0 }} >{member.email}</p> */}
                                            </div>
                                        </div>

                                    ))}


                                </div>)}

                            </div>

                            <div style={isDesktopOrLaptop ? { marginTop: 20 } : {}}>
                                <Badge value={props.channels && '#' + props.channels[flow.broadcastChannels[0]].name + ' channel'} severity="danger" size="large"></Badge>
                            </div>

                        </div>


                    </div>
                    <div className="col" >
                        <MediaQuery minWidth={471}>
                            <div className="flex justify-content-end flex-wrap m-4">
                                {props.userData && props.userData.userType == 'admin' ? (
                                    <Link to={'/edit-template/' + props.id} style={{ textDecoration: 'none', }}>
                                        <Button icon="pi pi-file-edit" disabled={false} label="Edit" rounded severity="secondary" aria-label="Edit" /></Link>

                                ) : (
                                    <Button icon="pi pi-file-edit" disabled={props.userData && props.userData.userType == 'admin' ? false : true} label="Edit" rounded severity="secondary" aria-label="Edit" />

                                )}
                            </div>
                        </MediaQuery>
                        <div className="border-round-sm font-bold  ">
                            <h3>Schedule</h3>
                            <div>
                                <p className="px-0 py-0 m-0" style={{ fontWeight: 200, color: '#959FA3' }}>
                                    WHEN
                                </p>
                                <p className="px-0 py-0 my-1">
                                    {flow?.schedule}
                                </p>
                                {flow?.schedule == 'Custom' ? (
                                    <Calendar value={dates} onChange={(e) => setDates(e.value)} inline selectionMode="multiple" style={{ width: '95%' }} />

                                ) : (
                                    <WeeksArray dateArrData={flow?.days} disableBtns={true} points={props.points} />
                                )}

                                {/* <WeeksArray dateArrData = {flow?.days} weeksObj = {flow?.weeks} disableBtns={false} points={props.points}  /> */}
                            </div>

                            {/* <Calendar value={dates} onChange={(e) => setDates(e.value)} inline selectionMode="multiple" style={{ width: '95%' }} /> */}
                        </div>

                        <div className="grid py-3">
                            <div className="col mr-5">
                                <Card title={"Out of Office 📅"} >

                                    <AvatarGroup>
                                        {props.statusList && props.statusList.map((member) => (
                                            <Avatar label={props?.participantObj.length && props?.participantObj[member.user.id]?.profile ? props?.participantObj[member.user.id]?.profile?.real_name[0] : props?.participantObj[member.user.id]?.real_name[0]}
                                                style={{ backgroundColor: props.participantObj && props.participantObj[member.user.id].color, color: '#ffffff' }} shape="circle" size="large" />
                                        ))}

                                    </AvatarGroup>
                                    <p>{props.statusList && props.statusList.length + " participants are out of the office today"} </p>
                                    <div className="flex justify-content-end flex-wrap" >
                                        {/* <div>
                                            <Button style={{ padding: 1 }} text severity="secondary" aria-label="Bookmark" >
                                                <h6 style={{ margin: 1, }}>Show all</h6> </Button>
                                        </div> */}

                                    </div>
                                </Card>
                            </div>

                            <div className="col mx-5">
                                <Card title={"Participation Today"} footer={<h5><i className="pi pi-circle-fill" style={{ width: 20, color: '#34B53A' }} />{"Reported " + props.pieChartData.count + " people out of " + props.pieChartData.participants}</h5>}>
                                    <div className="flex justify-content-center flex-wrap" >
                                        <div style={{ width: 115 }}>
                                            <div >
                                                <CircularProgressbar
                                                    value={props.pieChartData && props.pieChartData.percentage}
                                                    text={props.pieChartData && props.pieChartData.percentage + '%'}
                                                    background={true}
                                                    styles={{
                                                        path: {
                                                            stroke: '#34B53A',
                                                        },
                                                        trail: {
                                                            stroke: '#E2FBD7',
                                                        },
                                                        text: {
                                                            fill: '#34B53A',
                                                            fontSize: '20px',
                                                        },
                                                        background: {
                                                            fill: '#E2FBD7',
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </Card>
                            </div>
                        </div>
                    </div>

                </div>
            </Card>
        </>
    );
}

export default TemplateDetails;