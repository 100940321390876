
import React, { useEffect, useState } from 'react';
import { RetrievePaymentIntent } from '../../api/Stripe';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";

export default function CompletePage() {
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(null);
    useEffect(() => {
        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );
        const payment_intent = new URLSearchParams(window.location.search).get(
            'payment_intent'
        );
        successMessage(payment_intent)
        // Retrieve the PaymentIntent

    }, []);

    const successMessage = async (intent) => {

        const data = await RetrievePaymentIntent(intent);
        if (data.status == 'succeeded') {
            setMessage('Success! Payment received.')
            setStatus(data.status)
        } else if (data.status == 'processing') {
            setMessage("Payment processing. We'll update you when payment is received..")
            setStatus(data.status)
        } else if (data.status == 'requires_payment_method') {
            setMessage("Payment failed. Please try another payment method.")
            setStatus(data.status)
        } else {
            setMessage("Something went wrong..")
        }
    }

    const navigate = useNavigate();

    return (
        <Card style={{ height: '100vh' }}>

            <div className="flex justify-content-center" >
                {status == 'succeeded' ? (
                    <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '10rem' }}></i>
                ) : status == 'processing' ? (
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '10rem' }}></i>
                ) : status == 'requires_payment_method' ? (
                    <i className="pi pi-times-circle" style={{ color: 'red', fontSize: '10rem' }}></i>
                // ) : <i className="pi pi-spin pi-spinner" style={{ fontSize: '10rem' }}></i>}
            ) : null}
            </div>
            <div className="flex justify-content-center" >
                <h2>
                    {message}

                </h2>


            </div>

            <div className="flex justify-content-center" >

            </div>

            <div className="my-6 flex justify-content-center" >
                <Button className="px-5" label="GO TO BILLING" aria-label="Edit" onClick={() => navigate("/billing")} />
            </div>
        </Card>
    )
};