import { Outlet, useOutlet, Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import SidebarMobile from "../components/SidebarMobile";
import SlackSignIn from "./SlackSignIn";
import Page403 from "./Common/Page403";
import { getUser } from "../api/Users";
import { getOrganization, orgAdminDataPresent, orgUserDataPresent } from "../api/Organizations";
import { useState, useEffect, useContext } from "react";
import Icon from '../images/Icon.png';
import { ProgressSpinner } from 'primereact/progressspinner';
import AdminDataModal from "./Common/AdminDataModal";
import { flowsPresentById } from "../api/Flows";
import { SearchCustomer, ListSubscriptions } from '../api/Stripe';
import MediaQuery from 'react-responsive'
import { Button } from 'primereact/button';
import Logo2 from '../images/logo2.png';
import moment from "moment";
import { organizationType, user } from "../types/types";
import { AuthContext } from "../types/authContext";
import UserDataModal from "./Common/UserDataModal";

const Layout = (props) => {

    const [authObj, setAuthObj] = useState({});
    const userObj = useContext(AuthContext);
    const [user, setUser] = useState<user>();
    // const [profile, setProfile] = useState(false);
    const [organization, setOrganization] = useState<organizationType>();
    const [hideBanner, setHideBanner] = useState(true);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [userDialogVisible, setUserDialogVisible] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [flow, setFlow] = useState(null);
    const [subscription, setSubscription] = useState(false);
    const [validate, setValidate] = useState(true);
    const [loading, setLoading] = useState(false);
    const outlet = useOutlet()

    useEffect(() => {
        setTimeout(() => {
            // setAuthObj(JSON.parse(localStorage.getItem("auth")) ? JSON.parse(localStorage.getItem("auth")) : null)
            setAuthObj(userObj.auth);
            setHideBanner(JSON.parse(localStorage.getItem("hideBanner")) ? JSON.parse(localStorage.getItem("hideBanner")) : null)
            getTempalte(userObj.auth ? userObj.auth : null);
        }, 1000);

    }, [userObj]);


    const getTempalte = async (params) => {
        if (params) {
            setLoading(true);

            // let savedvalue = JSON.parse(localStorage.getItem("auth")) || {};
            let savedvalue = userObj.auth ? userObj.auth : {};
            // const count = await validateFlowsbyOrg(savedvalue.team.id);

            // if (count >= 3) {
            // const customerdata = await SearchCustomer(savedvalue.team.name);
            // const customer = customerdata?.data.id;
            // console.log(customer);
            // const subscriptions = await ListSubscriptions(customer);
            // setSubscription(subscriptions);
            // console.log(subscriptions);
            // if (!(customer && subscriptions && subscriptions.plan && subscriptions.plan.product == "prod_QrzLnAcOFAHwYv")) {
            //     setValidate(false);
            // } 
            // }
            const aData = await orgAdminDataPresent(params.team.id);
            const uData = await orgUserDataPresent(params.team.id + params.channel.slackUserId);
            setDialogVisible(!aData);
            setUserDialogVisible(!uData);
            const org = await getOrganization(params.team.id);
            const userD = await getUser(params.team.id + params.channel.slackUserId) as user;
            // if (aData) {
            // }
            if (outlet == null) {
                const flow = await flowsPresentById(savedvalue?.team.id);
                setFlow(flow)
                // if (flow == true) {
                //     navigate("/workflows");
                // } else {
                //     navigate("/templates");
                // }
            }



            // const hours = 1; // to clear the localStorage after 1 hour
            // // (if someone want to clear after 8hrs simply change hours=8)
            // const now = new Date().getTime();
            // const setupTime = localStorage.getItem('setupTime');
            // if (setupTime == null) {
            //     localStorage.setItem('setupTime', now)
            // } else {
            //     if (now - setupTime > hours * 60 * 60 * 1000) {
            //         localStorage.clear()
            //         localStorage.setItem('setupTime', now);
            //     }
            // }
            setUser(userD);
            // setAdminData(aData);
            setOrganization(org);

            setLoading(false);
        }
        setLoading(false);
    };

    const closeBanner = async () => {
        localStorage.setItem("hideBanner", "true");
        setHideBanner(true);
    }

    return (
        <div>
            <AdminDataModal status={dialogVisible} setStatus={setDialogVisible} />

            <UserDataModal status={!(dialogVisible) && userDialogVisible} setStatus={setUserDialogVisible} />

            {loading ? (
                <div className="flex align-items-center justify-content-center" >
                    <h1><ProgressSpinner /> </h1>
                </div>
            ) : !loading  && JSON.parse(localStorage.getItem("auth")) && authObj == null ? (
                <div className="flex align-items-center justify-content-center" >
                    <h1><ProgressSpinner /> </h1>
                </div>
            ): !loading && authObj == null ? (
                <SlackSignIn />
                // ) : authObj && validate == false ? (
                //     <ExceedUserCount />
            )  : user && user.activeStatus == false ? (
                <Page403 />
                // ) : authObj && profile == null ? (
                //     <Approval status="null" />
                // ) : authObj && organization && organization.activeStatus == "pending" ? (
                //     <Approval status="pending" />
                // ) : !subscription && organization && window.location.pathname != "/trial-expired" && !((window.location.pathname).includes("/billing"))

            ) : organization && organization.subscriptionStatus != "active" && window.location.pathname != "/trial-expired" && !((window.location.pathname).includes("/billing"))
                && (moment(organization.trialEndDate) < moment(moment().format("YYYY-MM-DD"))) ? (
                <Navigate to='/trial-expired' />
            ) : !loading && outlet && window.location.pathname == "/auth" ? (
                <>

                    <div className="flex overflow-auto">

                        {/* </div> */}
                        <div className="flex-grow-1 flex-row font-bold border-round">

                            <div>
                                <div>{outlet || <ProgressSpinner />}</div>
                            </div>
                        </div>
                    </div>
                </>
            ) : !loading && outlet && window.location.pathname != "/auth" ? (
                <>

                    <MediaQuery minWidth={471}>
                        <div className="flex overflow-auto">
                            {/* <div className="flex font-bold m-0 px-0 py-0 border-round overflow-hidden" style={{
                        // maxHeight: '70vh',
                        top: 0
                    }}> */}
                            <div className="flex sticky top-0">
                                <Sidebar collapsed={collapsed} setCollapsed={() => setCollapsed(!collapsed)} />
                            </div>
                            <div className="fixedSidebar">
                                <Sidebar collapsed={collapsed} setCollapsed={() => setCollapsed(!collapsed)} />
                            </div>
                            {/* </div> */}
                            <div className="flex-grow-1 flex-row font-bold border-round">

                                <div>
                                    {!hideBanner ? (
                                        <div style={{ backgroundColor: '#4339F2' }}>
                                            <div className="flex flex-row py-2 ">
                                                <div className="col-1 flex align-content-center justify-content-center">
                                                    <img alt="Card" src={Icon} width={'45rem'} height={'45rem'} />
                                                </div>
                                                <div className="col-10 text-white">
                                                    <h3 style={{ margin: 0 }}>This is a beta version </h3>
                                                    <p style={{ margin: 0 }}> This version is a test version of the Pulse plattform and all of it’s features. It is not launched yet but stay tuned! </p>
                                                </div>
                                                <div className="col-1 px-5 flex align-content-center justify-content-end text-white">
                                                    <button className="justify-content-center" type="submit" style={{ backgroundColor: "#4339F2", color: 'white', border: 'none' }} onClick={() => closeBanner()} >
                                                        <h3 ><span style={{ width: 30, }} className="pi pi-times"></span></h3>
                                                    </button>
                                                    {/* <span id="close" onclick="document.getElementById('modalWindow').style.display='none';">x</span> */}
                                                    {/* </Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div>{outlet || <ProgressSpinner />}</div>
                                </div>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={470} >
                        <div className="overflow-hidden">
                            <div className="flex align-content-start justify-content-start" style={{ backgroundColor: '#FFFFFF' }}>

                                <div className="flex align-content-start justify-content-center mx-2">
                                    <Button
                                        onClick={() => setCollapsed(!collapsed)}

                                        link
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <div>
                                            <i className="pi pi-bars" style={{ fontSize: '1rem' }}></i>
                                        </div></Button>
                                </div>

                                <img alt="Card" src={Logo2} width={'35%'} style={{ zIndex: 15 }} />

                            </div>
                            <div>
                                {!hideBanner ? (
                                    <div style={{ backgroundColor: '#4339F2' }} >
                                        <div className="flex flex-row py-2 ">
                                            <div className="col-2 flex align-content-center justify-content-center">
                                                <img alt="Card" src={Icon} width={'45rem'} height={'45rem'} />
                                            </div>
                                            <div className="col-9 text-white">
                                                <h3 style={{ margin: 0 }}>This is a beta version </h3>
                                                <p style={{ margin: 0 }}> This version is a test version of the Pulse plattform and all of it’s features. It is not launched yet but stay tuned! </p>
                                            </div>
                                            <div className="col-1 flex align-content-center justify-content-end text-white">
                                                <button className="justify-content-center" type="submit" style={{ backgroundColor: "#4339F2", color: 'white', border: 'none' }} onClick={() => closeBanner()} >
                                                    <h3 ><span style={{ width: 15, }} className="pi pi-times"></span></h3>
                                                </button>
                                                {/* <span id="close" onclick="document.getElementById('modalWindow').style.display='none';">x</span> */}

                                            </div>
                                        </div>
                                    </div>
                                ) : <div />}
                            </div>
                            <div className="">
                                <div className="sticky top-0">

                                    {collapsed && (
                                        <SidebarMobile collapsed={false} setCollapsed={() => setCollapsed(!collapsed)} />
                                    )}

                                    {!collapsed && (
                                        <div>{outlet || <ProgressSpinner />}</div>
                                    )}

                                </div>

                            </div>

                        </div>
                    </MediaQuery>
                </>
            ) : organization && organization.activeStatus == "active" && authObj && outlet == null && flow ? (
                <Navigate to='/workflows' />
            ) : organization && organization.activeStatus == "active" && authObj && outlet == null && !flow ? (
                <Navigate to='/templates' />
            ) : (
                <div className="flex align-items-center justify-content-center" >
                    <h1><ProgressSpinner /> </h1>
                </div>
            )}









        </div>
    )
};

export default Layout;