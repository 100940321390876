import React, { createContext, useState } from 'react';

// Create the context
export const ParticipantContext = createContext(null);

// Create a provider component
export const ParticipantProvider = ({ children }) => {
  const [participants, setParticipants] = useState(JSON.parse(window.localStorage.getItem("participantList")) || null);

  // Function to toggle the login state
//   const toggleLogin = () => {
//     setParticipants((prevState) => ({
//       ...prevState,
//       // loggedIn: !prevState.loggedIn,
//     }));
//   };

  return (
    <ParticipantContext.Provider value={{ participants, setParticipants }}>
      {children}
    </ParticipantContext.Provider>
  );
};