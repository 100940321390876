import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { Button } from "primereact/button";
const redirect_payment = process.env.REACT_APP_SLACK_REDIRECT_URI.slice(0, -4) + "billing/payment"
const redirect_card = process.env.REACT_APP_SLACK_REDIRECT_URI.slice(0, -4) + "billing/new-card"


export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const clientSecret = props.clientSecret;
    const secretType = props.secretType;
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        if (secretType == "payment") {
            const { error } = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: redirect_payment,
                }
            });
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        } else if (secretType == "newCard") {
            const { error } = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: redirect_card,
                }
            });
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        } else {
            // const data = await CreateSetupIntent('cus_QjjBqKoZzybnbN');
            // const { error } = await stripe.confirmPayment({
            //     //`Elements` instance that was used to create the Payment Element
            //     elements,
            //     confirmParams: {
            //         return_url: "https://example.com/order/123/complete",
            //     }
            // });
            //   console.log(setupIntent);
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs" as const
    }

    return (

            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <div className="flex justify-content-end" >

               
                <Button className="mt-2 mr-2" severity='secondary' onClick={() => props.cancelBtn ? props.cancelBtn() : props.setView("package")}>Cancel</Button>
                <Button className="mt-2 ml-2" disabled={isLoading || !stripe || !elements} id="submit" >
                    <span id="button-text">
                        {/* {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"} */}
                        Pay now
                    </span></Button>
                </div>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
    

    );
}