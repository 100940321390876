import React, { createContext, useState } from 'react';

// Create the context
export const AuthContext = createContext(null);

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(JSON.parse(window.localStorage.getItem("auth")) || null);

  // Function to toggle the login state
  // const toggleLogin = () => {
  //   setAuth((prevState) => ({
  //     ...prevState,
  //     // loggedIn: !prevState.loggedIn,
  //   }));
  // };

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};